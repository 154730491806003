import { BadgeList, BadgeListItem } from '../styles/shared.css'

export default function AccomplishmentBadges({ accomplishments }) {
  const impressiveAccomplishments = [
    "Sold Company",
    "Took company public",
    "core contributor to popular repo",
    "Founded profitable company",
    "has been C-level or VP",
    "award winner",
    "built something great",
    "speaker / writer / thought-leader",
    "Released a successful game",
    "Awarded Patent",
    "Published Research",
  ]

  const impressiveAccomplishmentsPrettyNames = {
    "Sold Company": "Sold Company",
    "Took company public": "IPO",
    "core contributor to popular repo": "Open Source",
    "Founded profitable company": "Profitable Startup",
    "has been C-level or VP": "Leadership",
    "award winner": "Award Winner",
    "built something great": "Built Something Great",
    "speaker / writer / thought-leader": "Thought Leader",
    "Released a successful game": "Released a Game",
    "Awarded Patent": "Patent",
    "Published Research": "Published Research",
  }

  const badges = impressiveAccomplishments.reduce((acc, ia) => {
    if (accomplishments.includes(ia)) {
      return [...acc, impressiveAccomplishmentsPrettyNames[ia]]
    }
    return acc
  }, [])

  return <BadgeList>
    {badges.map(badge => (
      <BadgeListItem key={badge}>{badge}</BadgeListItem>
    ))}
  </BadgeList>
}