import { useEffect, useState, useRef } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  ProfileSummary, ShareProfileContainer, ShareForm, ShareProfileHeader,
  ShareProfileSubheader, ShareURLTextInput, ExpandProfile,
} from '../styles/profile.css'
import { Container, Button } from '../styles/shared.css'

export const ProfileParagraph = ({ profile }) => {
  const [expanded, setExpanded] = useState(false)

  const delimiter = (max, idx, conjunction = ' and ') => {
    if (max == 2 && idx == 1) {
      return conjunction
    }

    if (max > 1 && idx == max - 1) {
      return conjunction
    }

    return null
  }

  const toValue = (item) => {
    return <span className='value' key={item}>{item}</span>
  }

  const toList = (items, conjunction) => {
    if (!items) return null

    return items.map((i, idx) => (
      <span key={idx}>
        {delimiter(items.length, idx, conjunction)}
        {toValue(i)}
      </span>
    ))
  }

  const NUM_ITEMS_TO_DISPLAY = 5

  return <ProfileSummary expanded={expanded}>
    Wants to make {' '}
    {toValue(profile.money)}
    working {' '}
    {toValue(profile.availability)}
    {toValue(profile.location)}
    at a {' '}
    {toList(profile.size, 'or ')} company.

    <br />
    <br />They enjoy {' '}
    {toList(profile.likes.slice(0, NUM_ITEMS_TO_DISPLAY), 'and ')}
    and want to get better at {' '}
    {toList(profile.responsibilities.slice(0, NUM_ITEMS_TO_DISPLAY))}.

    <br />
    <br />Cultural values like {' '}
    {toList(profile.team.slice(0, NUM_ITEMS_TO_DISPLAY), '')}
    {toList(profile.health.slice(0, NUM_ITEMS_TO_DISPLAY), '')}
    {toList(profile.career.slice(0, NUM_ITEMS_TO_DISPLAY), '')} are important to them.

    <br />
    <br />They're looking for teams that {' '}
    {toList(profile.engineering.slice(0, NUM_ITEMS_TO_DISPLAY), '')}
    and philosophies like&nbsp;
    {toList(profile.philosophy.slice(0, NUM_ITEMS_TO_DISPLAY), 'and ')}.
    {!expanded &&
      <ExpandProfile onClick={() => setExpanded(true)}>View More</ExpandProfile>
    }
  </ProfileSummary>
}

export const ShareProfile = ({ slug }) => {
  const shareProfileRef = useRef(null)
  const [copied, setCopied] = useState(false)

  const url = `${window.location.origin}/${slug}`

  useEffect(() => {
    function handleResize() {
      const vh = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight)
      const elementHeight = vh - shareProfileRef.current.offsetTop
      shareProfileRef.current.style.height = `${elementHeight}px`
    }
    handleResize()
    window.addEventListener('resize', handleResize)
  }, [])

  const onCopy = () => {
    setCopied(true)
  }

  return <ShareProfileContainer ref={shareProfileRef}>
    <Container>
      <ShareProfileHeader>Invite developers you know to work with you.</ShareProfileHeader>
      <ShareProfileSubheader>
        You'll appear higher in search results and be more attractive to potential employers
        the more other developers want to work with you.
      </ShareProfileSubheader>
      <CopyToClipboard text={url} onCopy={onCopy}>
        <ShareForm>
          <ShareURLTextInput type='text'
            value={url}
            readOnly
            autoFocus
            onFocus={e => e.currentTarget.select()}
          />
          <Button as='button'>{copied ? 'Copied!' : 'Copy'}</Button>
        </ShareForm>
      </CopyToClipboard>
    </Container>
  </ShareProfileContainer>
}