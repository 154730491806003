import styled from 'styled-components'
import { Flex, Title, Subtitle, TextInput } from './shared.css'
import { breakpoints } from './shared.css'

export const ProfileSummary = styled.p`
  position: relative;
  padding: 20px 0 60px;
  border-top: 1px solid #222;
  margin: 40px 0 0;
  font-size: 18px;
  line-height: 30px;

  height: 30vh;
  overflow: hidden;

  ${({ expanded }) => expanded && `
    height: auto;
    overflow: auto;
  `}

  span.value {
    text-transform: capitalize;
    display: inline-block;
    font-weight: 600;
    background: #ddd;
    padding: 0px 8px;
    margin: 0 4px 6px 0;
    border-radius: 4px;
    font-size: 16px;
  }
`

export const ExpandProfile = styled.button`
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, sans-serif;
  font-weight: 700;
  position: absolute;
  background: linear-gradient(180deg, rgba(246, 248, 247, 0), rgba(246, 248, 247, 1) 50%);
  bottom: 0;
  left: 0;
  padding: 65px 0 15px;
  text-align: center;
  width: 100%;
  border: none;
  cursor: pointer;
`

export const ShareProfileContainer = styled.div`
  margin: 0;
  background-color: #cdd1e4;
  padding: 50px 0;

  @media (max-width: ${breakpoints.small}) {
    margin: 20px 0 0;
  }
`

export const ShareProfileHeader = styled.h2`
  font-size: 42px;
  line-height: 44px;
  font-weight: 700;

`

export const ShareForm = styled(Flex)`
  margin: 30px 0 10px;
  align-items: center;
`

export const ShareProfileSubheader = styled(Subtitle)`
  margin: 20px 0 0;
  font-size: 18px;
  line-height: 25px;
  font-weight: lighter;
`

export const ShareURLTextInput = styled(TextInput)`
  font-size: 16px;
  font-weight: normal;
  background: #fff;
`

export const ProfileTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ProfileEditButton = styled.a`
  align-self: end;
  margin-bottom: 12px;

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  span {
    vertical-align: middle;
    margin-left: 4px;
    padding: 4px 0 0;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.02em;
  }

  svg {
    width: 24px;
    vertical-align: middle;
  }
`

export const ProfileTitle = styled(Title)`
  margin-top: 45px;
  text-transform: capitalize;
  font-size: 42px;

  a {
    &:hover {
      text-decoration: underline;
    }
  }
`

export const ProfileSubtitle = styled(Subtitle)`
  font-size: 14px;
  font-weight: 300;
  color: #555;
  text-indent: 2px;

  a {
    font-weight: 300;
    text-transform: none;

    &:hover {
      text-decoration:underline;
    }
  }

  em {
    font-weight: bold;
    color: #222;
    font-size: 16px;
  }
`

export const ProfileActions = styled.div`
  width: 100%;
  transition: all 0.2s ease-in-out;
  padding-top: 20px;
  border-top: 2px solid #39DCBF;

  &.color {
    background-color: #fff;
  }
`
