import { useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import { useRouter } from 'next/router'

import Meta from '../../components/meta'
import MovedByNav from '../../components/movedByNav'
import Nav from '../../components/nav'
import Header from '../../components/header'
import Flash from '../../components/flash'
import Questions from '../../components/questions'
import QuestionnairePrompt from '../../components/questionnairePrompt'
import AccomplishmentBadges from '../../components/accomplishmentBadges'
import { ProfileParagraph, ShareProfile } from '../../components/profile'

import api, { get } from '../../lib/api'
import { makeSubmitOnboardingPayload } from '../../lib/helpers'
import { questions, order } from '../../questions/onboarding'
import { questionsOrderState, flashState, answersState } from '../../state/atoms'
import { currentUserSelector } from '../../state/selectors'
import { Container } from '../../styles/shared.css'
import { ProfileTitleContainer, ProfileTitle, ProfileEditButton, ProfileSubtitle, ProfileActions } from '../../styles/profile.css'

export default function Profile() {
  const currentUser = useRecoilValue(currentUserSelector)
  const setAnswers = useSetRecoilState(answersState)
  const router = useRouter()
  const { userSlug } = router.query
  const [data, setData] = useState(null)

  useEffect(() => {
    const fetchProfile = async () => {
      const res = await get(`users/${userSlug}`)
      const data = await res.json()
      setData(data)
      setAnswers({ follow: data.following })
    }
    if (typeof userSlug != 'undefined') {
      fetchProfile()
    }
  }, [userSlug, setAnswers])

  if (!data) return null

  const profileWasRecentlyCreatedLocally = () => {
    const localStorageProfileItem = localStorage.getItem(`polyfill-${userSlug}`)

    if (!localStorageProfileItem) {
      return false
    }

    const now = new Date().getTime()
    const diffInMilliseconds = now - localStorageProfileItem
    const diffInMinutes = Math.round(diffInMilliseconds / 60000)
    return diffInMinutes <= 30
  }

  const isMyProfile = (currentUser && currentUser.id === data.id) || profileWasRecentlyCreatedLocally()

  const profileActions = () => {
    if (currentUser) {
      return <FollowQuestion name={data.name} userSlug={userSlug} />
    } else {
      return <ProfileQuestions name={data.name} userSlug={userSlug} />
    }
  }

  const numFollows = data.follows
  const developers = numFollows == 1 ? 'developer' : 'developers'
  const like = numFollows == 1 ? 'likes' : 'like'
  const firstName = data.name.split(' ')[0]

  return <>
    <Meta />
    <MovedByNav>
      <Header stuck={true} />
      <Flash />
      <Container>
        <ProfileTitleContainer>
          <ProfileTitle>{data.name}</ProfileTitle>
          {isMyProfile && <ProfileEditButton href={'/'}><EditIcon /><span>Edit Profile</span></ProfileEditButton>}
        </ProfileTitleContainer>
        <ProfileSubtitle>
          <a href={`/${userSlug}/followers`}><em>{numFollows}</em> other {developers} {like} working with {firstName}</a>
        </ProfileSubtitle>
        <AccomplishmentBadges accomplishments={data.accomplishments} />
        <ProfileParagraph profile={data} />
      </Container>
      {isMyProfile &&
        <ShareProfile slug={userSlug} />
      }
      {!isMyProfile &&
        <ProfileActions id='profile-actions' className='color'>
          <Container>
            {profileActions()}
          </Container>
        </ProfileActions>
      }
    </MovedByNav>
    <Nav />
  </>
}

Profile.getLayout = function getLayout(page) {
  return <>{page}</>
}

const EditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
    </svg>
  )
}


const generateFollowQuestion = (name) => {
  return {
    slug: 'follow',
    prompt: `Would you work with ${name.split(' ')[0]}?`,
    type: 'select-one',
    choices: () => ['Yes', 'No']
  }
}

const ProfileQuestions = ({ name, userSlug }) => {
  const [questionsOrder, setQuestionsOrder] = useRecoilState(questionsOrderState)
  const setFlash = useSetRecoilState(flashState)
  const currentUser = useRecoilValue(currentUserSelector)
  const follow = generateFollowQuestion(name)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => { setQuestionsOrder(['follow', ...order]) }, [setQuestionsOrder])

  const handleSubmit = async (questions, answers) => {
    // NOTE if we change this method we also have to change the same
    // handler on the homepage (they can sign up from there as well)

    if (isSubmitting) { return }
    setIsSubmitting(true)
    const payload = makeSubmitOnboardingPayload(questions, answersWithFollow(answers, userSlug))
    const request = await api('users', payload)
    const response = await request.json()
    setIsSubmitting(false)

    if (request.status == 200) {
      setFlash(`<em>Your profile has been created!</em> We'll email you when we find jobs you'll like.`)
      if (!currentUser) {
        localStorage.setItem(`polyfill-${response.id}`, new Date().getTime())
      }
      window.location = `/${response.id}`
    } else if (request.status == 401) {
      alert("A user with that email address already exists.")
    } else {
      alert(JSON.stringify(response))
    }
  }

  const answersWithFollow = (answers, userSlug) => {
    if (answers['follow'].includes('Yes')) {
      return { ...answers, ...{ follow: userSlug } }
    } else {
      // eslint-disable-next-line no-unused-vars
      const { follow, ...answersWithNoFollow } = answers
      return answersWithNoFollow
    }
  }

  if (questionsOrder.length >= 1) {
    questions[questionsOrder[1]].subtitle = <QuestionnairePrompt />
  }

  return <Questions
    questions={{ ...{ follow }, ...questions }}
    pageName='profile'
    submitButtonLabel='Create Account'
    onSubmit={handleSubmit}
    isSubmitting={isSubmitting}
  />
}

const FollowQuestion = ({ name, userSlug }) => {
  const setQuestionsOrder = useSetRecoilState(questionsOrderState)
  const follow = generateFollowQuestion(name)

  useEffect(() => { setQuestionsOrder(['follow']) }, [setQuestionsOrder])

  const handleSubmit = async (_, answers) => {
    if (answers['follow'].includes('Yes')) {
      await api('follows', { userSlug })
    } else {
      await api(`follows/${userSlug}`, {}, 'DELETE')
    }
  }

  return <Questions
    questions={{ follow }}
    pageName='profile'
    onSubmit={handleSubmit}
  />
}